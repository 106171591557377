import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { RichText } from '@graphcms/rich-text-react-renderer';
import {
  H8CamptonSemiBold20,
  ContentSmallCamptonBook,
  GreenLinkText
} from '../../../../layout/globalFonts';
import { PartnersMkLinksTo, ProductInfo, Service } from '../../../../swagger/api/index';
import { ReactComponent as CheckIcon } from '../../../../layout/icons/check.svg';
import ContentStore from '../../../../stores/ContentStore';
import ConfirmOpenOresundLinkModal from '../modals/ConfirmOpenOresundLinkModal';
import NoIdentifierFoundWaitAMoment from '../modals/NoIdentifierFoundWaitAMoment';

const HeaderSection = styled(Row)`
  padding-bottom: 10px;
`;

const StyledRow = styled(Row)`
  flex-direction: column;
  gap: 12px;
  line-height: 1.2;
`;

const StyledWrapperRow = styled(StyledRow)`
  gap: 36px;
`;

const StyledLink = styled(GreenLinkText)`
  display: block;
`;

interface LocalServicesProps {
  product: ProductInfo;
  contentStore: ContentStore;
}

const LocalServices: FC<LocalServicesProps> = (props) => {
  const { product, contentStore } = props;
  const { loadingStore, manageUserAccessStore, modalStore } = contentStore.rootStore;
  const { productCardStore } = contentStore.rootStore.productStore;
  const isPrivateCustomer: boolean = manageUserAccessStore.isPrivateCustomer();

  const getPartnerLink = (partner: PartnersMkLinksTo) => {
    loadingStore.enableGlobalSpinner();
    productCardStore
      .getPartnerLink(partner)
      .then((url) => {
        loadingStore.disableGlobalSpinner();
        modalStore.openModal(
          <ConfirmOpenOresundLinkModal
            closeModal={modalStore.closeModal}
            oresundUrl={url!}
            contentStore={contentStore}
          />
        );
      })
      .catch((error) => {
        if (error.response?.data.errorCode.includes('NoIdentifierFoundByPartner')) {
          modalStore.openModal(
            <NoIdentifierFoundWaitAMoment modalStore={modalStore} contentStore={contentStore} />
          );
        }
      })
      .finally(() => loadingStore.disableGlobalSpinner());
  };

  const agreement = (service: Service) => {
    let serviceSystemId = contentStore.cmsStore.getContent(service.name!)?.content;
    if (serviceSystemId?.includes('Norge') || serviceSystemId?.includes('Norway'))
      serviceSystemId = 'NorwayAgreementsServiceAgreement';
    return (
      serviceSystemId !== undefined &&
      service.isActive && (
        <Row style={{ maxHeight: 32, alignItems: 'center' }}>
          <Col span={2}>
            <CheckIcon />
          </Col>
          <Col span={22}>
            <ContentSmallCamptonBook>
              {contentStore.cmsStore.getContent(`${serviceSystemId}OnProductCard`)?.content}
            </ContentSmallCamptonBook>
          </Col>
        </Row>
      )
    );
  };

  const agreementRows = product.servicesOnIdentifier
    ?.filter(
      (service: Service) =>
        service.isActive && contentStore.cmsStore.getContent(service.name!)?.content
    )
    .map((service: Service) => agreement(service));

  const getOresundDiscountLink = () =>
    !product.servicesOnIdentifier?.some(
      (s: Service) =>
        (contentStore.cmsStore.getContent(s.name!)?.content === 'OresundServiceAgreement' ||
          contentStore.cmsStore.getContent(s.name!)?.content ===
            'OeresundCommercialServiceAgreement') &&
        s.isActive !== false
    ) && (
      <StyledLink
        style={{ cursor: 'pointer' }}
        onClick={() => getPartnerLink(PartnersMkLinksTo.BropassOresund)}
      >
        {contentStore.cmsStore.getContent('OresundServiceAgreementGetDiscountLinkName')?.content}
      </StyledLink>
    );

  const norwayAgreementLink = () =>
    !product.servicesOnIdentifier?.some(
      (s: Service) =>
        contentStore.cmsStore.getContent(s.name!)?.content === 'NorwayAgreementsServiceAgreement'
    ) && (
      <StyledLink
        style={{ cursor: 'pointer' }}
        onClick={() =>
          window.open(
            contentStore.cmsStore.getContent('NorwayAgreementsServiceAgreementLink')?.content,
            '_blank'
          )
        }
      >
        {contentStore.cmsStore.getContent('NorwayAgreementsServiceAgreementLinkName')?.content}
      </StyledLink>
    );

  return (
    <div>
      <HeaderSection>
        <H8CamptonSemiBold20>
          {contentStore.cmsStore.getContent('LocalServices')?.content}
        </H8CamptonSemiBold20>
      </HeaderSection>
      <StyledWrapperRow>
        {!!agreementRows && agreementRows.length > 0 && <StyledRow>{agreementRows}</StyledRow>}
        <StyledRow>
          {getOresundDiscountLink()}
          {!isPrivateCustomer && norwayAgreementLink()}
          <GreenLinkText>
            <RichText
              content={
                contentStore.cmsStore.getContent('MoreAboutLocalServices')?.richContent?.raw ?? []
              }
              renderers={{
                p: ({ children }) => <p style={{ marginBottom: '0px' }}>{children}</p>
              }}
            />
          </GreenLinkText>
        </StyledRow>
      </StyledWrapperRow>
    </div>
  );
};

export default LocalServices;
