/* eslint-disable import/no-cycle */
import { action, makeObservable } from 'mobx';
import { CreateCustomerServiceIncidentRequest, CustomerServiceService } from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

export default class CustomerServiceStore extends BaseStore {
  requestToCloseAccount = (accountNumber: string, deactivationReason: string) => {
    const body: CreateCustomerServiceIncidentRequest = {};
    body.subject = 'Anmodning om at lukke underkonto';
    body.firstName = this.rootStore.userStore.person?.firstName;
    body.lastName = this.rootStore.userStore.person?.lastName;
    body.email = this.rootStore.userStore.person?.email;
    body.customerNumber = accountNumber;
    body.files = [];
    body.description = `email: ${this.rootStore.userStore.person!.email},\nfirstName: ${
      this.rootStore.userStore.person!.firstName
    },\nlastName: ${
      this.rootStore.userStore.person?.lastName
    },\ncustomerNumberOfCustomerMakingRequest: ${accountNumber},\nRequest to close ${accountNumber}.\nReason: ${deactivationReason}`;
    const promise = CustomerServiceService.createCustomerServiceIncident({ body });
    return promise;
  };

  requestToApproveInvoiceCustomer = (
    customerNumber: string,
    cvrNumber: string,
    companyName: string,
    expectMinimumSpendingAmount?: string
  ) => {
    const currentPerson = this.rootStore.userStore.person;
    const currentCustomerNumber = this.rootStore.userStore.currentAccount?.customerNumber;
    const body: CreateCustomerServiceIncidentRequest = {};
    body.firstName = currentPerson?.firstName;
    body.lastName = currentPerson?.lastName;
    body.email = this.rootStore.userStore.person?.email;
    body.files = [];
    body.subject = `Anmodning om fakturaaftale på kontoen med kundenummer: ${customerNumber}`;
    body.customerNumber = currentCustomerNumber;
    body.description = `currentPersonEmail: ${currentPerson?.email}, currentPersonFirstName: ${
      currentPerson?.firstName
    },\ncurrentPersonLastName: ${currentPerson?.lastName},\n${
      currentCustomerNumber !== undefined
        ? `customerNumberOfCustomerMakingRequest: ${currentCustomerNumber},\n`
        : ''
    }customerNumberOfCustomerToBeApproved: ${customerNumber},\nCvrNumberOfCustomerToBeApproved: ${cvrNumber},\ncompanyNameOfCustomerToBeApproved: ${companyName}${
      expectMinimumSpendingAmount !== undefined
        ? `,\nexpectMinimumSpendingAmount: ${expectMinimumSpendingAmount}`
        : ''
    }`;
    const promise = CustomerServiceService.createCustomerServiceIncident({ body });
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      requestToApproveInvoiceCustomer: action,
      requestToCloseAccount: action
    });
  }
}
